.container {
    /* max-width: fit-content; */
  }
  
  .bannerIMG {
    position: relative;
    width: 100%;
  }
  .bannerimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .bannerText {
    position: absolute;
    top: 40%;
    right: 50%;
    max-width: 35%;
    color: aliceblue;
    font-family: poppins;
    font-weight: bold;
  }
  .bannerText h1 {
    color: aliceblue;
    font-size: 2rem;
    font-family: poppins;
    font-weight: bold;
  }
  .bannerText p {
    color: aliceblue;
    font-size: 1rem;
    font-family: poppins;
    font-weight: bold;
  }
  .joinBtn {
    color: antiquewhite;
    background: blue;
    border: none;
    border-radius: 5px;
  }
  .joinBtn:hover {
    background: black;
    color: aliceblue;
    transition: 1.9s ease-in-out;
  }
  .benefits {
    max-width: 100%;
    /* border: 2px dashed red; */
    padding: 2%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 20px;
    margin-bottom: 5%;
  }
  .benefits h3 {
    font-size: 1rem;
    font-family: poppins;
    font-weight: bold;
  }
  .benefits h1 {
    font-size: 1rem;
    font-family: poppins;
    font-weight: bold;
  }
  
  .banefit {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .banefit p {
    font-size: 0.9rem;
    font-family: poppins;
  }
  
  .icon {
    font-size: 50px;
    color: aqua;
  }
  .contact {
    width: 100%;
    /* padding: 2%; */
    display: flex;
  
    justify-content: center;
    margin-bottom: 5%;
  }
  .contactImg {
    width: 37%;
  }
  .contactImg img {
    width: 100%;
    height: 100%;
    border-radius: 0 12px 12px 0;
    opacity: 0.9;
    /* object-fit: cover; */
    display: block;
  }
  .officeMail {
    width: 42%;
    height: 70%;
    background: rgb(224, 228, 235);
    padding: 5%;
    border-radius: 12px 0 0 12px;
  }
  .NameAndEmail {
    display: flex;
  }
  .inputbox {
    width: 100%;
    height: 46px;
    border: none;
    border-radius: 5px;
    margin: 5px;
    font-size: 19px;
    color: #373738;
    font-weight: lighter;
    font-family: serif;
  }
  .inputbox::placeholder {
    font-size: 19px;
    color: #878d97;
    font-weight: lighter;
    font-family: serif;
    padding: 5px;
  }
  .contactLable {
    font-size: 20px;
    color: #878d97;
    font-weight: lighter;
    font-family: serif;
    /* margin-bottom: 15px; */
  }
  #select {
    height: 46px;
    width: 100%;
    border: none;
    border-radius: 5px;
    margin: 5px;
    font-size: 19px;
    color: #424243;
    font-weight: lighter;
    font-family: serif;
    padding: 5px;
  }
  .textarea {
    width: 100%;
    height: 200px;
    border: none;
    border-radius: 5px;
    margin: 5px;
    font-size: 19px;
    color: #48494a;
    font-weight: lighter;
    font-family: serif;
    padding: 5px;
  }
  
  .contactBtn {
    width: 100%;
    height: 46px;
    border: none;
    border-radius: 5px;
    margin: 5px;
    font-size: 19px;
    color: white;
    font-weight: lighter;
    font-family: serif;
    padding: 5px;
    background: rgb(21 43 84);
  }
  @media (min-width:500px) and (max-width: 950px) {
    .bannerIMG {
    position: relative;
    width: 100%;
    height: 35vh;
  }
  .bannerimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
    .bannerText {
      top: 26%;
      left: 7%;
      max-width: 100%;
      width: 90%;
    }
    .bannerText h1 {
      font-size: 2.3rem;
    }
    .bannerText p {
      font-size: 1.2rem;
    }
    .joinBtn {
      font-size: 10px;
    }
    .benefits {
      grid-template-columns: repeat(1, 1fr);
    }
    .contact {
      flex-direction: column-reverse;
      align-items: center;
    }
    .officeMail {
      width: 80%;
      border-radius: 0 0 12px 12px;
    }
    .contactImg {
      width: 80%;
      height: 60vh;
    }
    .contactImg img{
      border-radius: 12px 12px 0 0;
    }
  }
  
  @media screen and (min-width: 300px) and (max-width: 500px) {
    .bannerIMG {
        position: relative;
        width: 100%;
        height: 30vh;
      }
      .bannerimg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    .bannerText {
      top: 25%;
      left: 7%;
      max-width: 90%;
      width: 80%;
    }
    .bannerText h1 {
      font-size: 1.5rem;
    }
    .bannerText p {
      font-size: 0.7rem;
    }
    .contact {
      flex-direction: column-reverse;
      justify-content: center;
      justify-items: center;
      align-items: center;
    }
    .officeMail {
      width: 90%;
      border-radius: 0 0 12px 12px;
    }
    .contactImg {
      width: 90%;
      height: 50vh;
      
    }
    .contactImg img{
      border-radius: 12px 12px 0 0;
    }
    .inputbox::placeholder {
      font-size: 10px;
    }
    #select {
      font-size: 10px;
    }
    .benefits {
        max-width: 100%;
        /* border: 2px dashed red; */
        padding: 2%;
        left: 6%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
        gap: 20px;
        margin-bottom: 5%;
      }
  }
  
  @media screen and (min-width: 1300px) and (max-width: 2440px) {
    .bannerText {
      top: 30%;
      right: 10%;
      max-width: 100%;
    }
    .bannerText h1 {
      font-size: 0.7rem;
    }
    .bannerText p {
      font-size: 0.3rem;
    }
    .contact {
      width: 100%;
      /* padding: 2%; */
      display: flex;
    align-items: center;
      justify-content: center;
      margin-bottom: 5%;
    }
    .contactImg {
      width: 37%;
      height: 94vh;
    }
    .contactImg img {
      width: 100%;
      height: 100%;
      border-radius: 0 12px 12px 0;
      opacity: 0.9;
      /* object-fit: cover; */
      display: block;
    }
    .officeMail {
      width: 42%;
      height: 70%;
      background: rgb(224, 228, 235);
      padding: 5%;
      border-radius: 12px 0 0 12px;
    }
    .inputbox::placeholder {
      font-size: 10px;
    }
    #select {
      font-size: 10px;
    }
  }
  
  @media screen and (min-width: 950px) and (max-width: 1300px) {
    .bannerIMG {
        position: relative;
        width: 100%;
        height: 45vh;
      }
      .bannerimg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    .bannerText {
      top: 30%;
      left: 10%;
      max-width: 100%;
      width: 50%;
      line-height: 1.3;
    }
    .bannerText h1 {
      font-size: 2.7rem;
      margin-bottom: 10px;
    }
    .bannerText p {
      font-size: 1.4rem;
    }
  }