  /* Terms and Conditions Styles */
  
  .container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper {
    padding: 20px;
  }
  
  .heading {
    font-size: 24px;
    color: #333;
  }
  
  .subheading {
    font-size: 18px;
    color: #333;
  }
  
  .paragraph {
    font-size: 16px;
    color: #666;
  }
  
  
  /* Responsive Design */
  
  @media (max-width: 768px) {
    .container {
      max-width: 100%;
      margin: 20px;
    }
  
    .heading {
      font-size: 20px;
    }
  
    .subheading {
      font-size: 16px;
    }
  
    .paragraph {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 10px;
    }
  
    .wrapper {
      padding: 10px;
    }
  
    .heading {
      font-size: 18px;
    }
  
    .subheading {
      font-size: 14px;
    }
  
    .paragraph {
      font-size: 12px;
    }
  }