.banner{
  width: 100%;
  height: 42vh;
 
}

.bannerimg {
  width: 100%;
  height: 110vh;
  margin: 0;
  padding: 0;
  
}
.aboutusinfo{
  width: 100%;
  height: 50vh;
  display: inline-flex;
  
}
.aboutusimg{
  width: 50px;
  height: 40px;

}

.aboutuscontent{
  width: 600px;
  height: 450px;

}
.aboutuscontent h1{
 font-size: 28px;

}
.title{
  width: 100%;
  height: auto;
  margin: 30px 0 ;
  background-color: rgb(51, 57, 92);
  align-items: center;
  padding: 30px 0;
}
.title h4{
 color: rgb(240, 240, 45);
 font-size: 24px;
 font-weight: 600;
 text-align: center;

}

.title h1{
  color: rgb(252, 248, 248);
  font-size: 44px;
  font-weight: 700;
  text-align: center;
  
}
.owner{
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  padding: 20px 70px;
  gap: 30px;
  align-items: center;
 
}
.ownerimg{
  width: 400px ;
  height: 430px;
  border-radius: 30px;
  border: 5px solid white;
  background-color: white;
  padding: 3px;
  margin: 20px 50px;
}
.ownerimg img{
  width: 400px ;
  height: 430px;
  border-radius: 30px;
  border: 5px solid white;


}
.ownerimg:hover{
  width: 410px ;
  height: 440px;
  border-radius: 30px;
  box-shadow: 2px 5px 5px 5px rgb(3, 3, 3);
  

}
.ownerinfo{
  width: 630px;
  height: 420px;
  overflow: hidden;
  justify-content: center;
  right: -80px;
  margin-top: 80px;

}
.ownerinfo h3{
  font-size: 25px;
  font-weight: 500px;
  color: white;
  
}
.ownerinfo h3 span{
  font-size: 28px;
  font-weight: 700px;
  color: rgb(247, 129, 19);
  
}
/* .carouselimg{
  width: 100%;
  height: auto;
} */


.carouselcontainer {
  width: 100%;
  height: 580px;
  max-width: 60%;
  margin: 50px auto;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  text-align: center;
  align-items: center;
}
.carouselcontainer h1{
  font-size: 45px;
  font-weight: 900;
  color: rgb(56, 62, 90);
  word-spacing: 1.2;
  margin: 30px auto;
}
/* Slide wrapper */
.carouselslides {
  display: flex;
  transition: transform 0.9s ease;
  width: 100%;
  gap: 10px;
}

/* Each slide */
.carouselslide {
  min-width: 100%;
}

/* Image style */
.carouselslide img {
  width: 100%;
  height: 450px;
  border-radius: 30px;
  display: block;
}
.carouselslides {
  animation: slide 20s infinite;
}

/* Animation */
@keyframes slide {
  0% { transform: translateX(0%); }
  20% { transform: translateX(0%); }
  25% { transform: translateX(-100%); }
  45% { transform: translateX(-200%); }
  50% { transform: translateX(-300%); }
  70% { transform: translateX(-400%); }
  75% { transform: translateX(-500%); }
  95% { transform: translateX(-600%); }
  100% { transform: translateX(-680%); }
}

/* Applying the animation */
.carousel-slides {
  animation: slide 5s infinite;
}

.visionmission{
  padding: 50px 130px;
  margin: 30px 0px;
  text-align: center;
  width: 100%;
}

.visionmission h1{
  font-size: 50px;
  font-weight: 800;
  color: black;
  margin: 30px 0;
}
.purpose{
  display: inline-flex;
  gap: 20px;
  margin: 40px auto;
}
.vision{
  background-color: #0B2048;
  text-align: center;
  width: 32%;
  height: 250px;
  padding: 10px 23px;
  border-radius: 30px;
}
.vision img{
 border-radius: 50%;
 width: 100px;
 height: 100px;
 margin-top: -45px;
 background-color: #0B2048;
 padding: 0.5px;
}
.vision h3{
  font-size: 28px;
  color: white;
  margin: 8px 0;
}
.vision p{
  font-size: 15px;
  color: rgb(218, 216, 216);
}
.mission{
  background-color: #0B2048;
  text-align: center;
  width: 32%;
  height: 250px;
  padding: 10px 23px;
  border-radius: 30px;
}
.mission img{
  border-radius: 50%;
 width: 100px;
 height: 100px;
 margin-top: -45px;
 background-color: #0B2048;
 padding: 6px;
 margin-bottom: 10px;
}
.mission h3{
  font-size: 28px;
  color: white;
  margin: 8px 0;
}
.mission p{
  font-size: 15px;
  color: rgb(218, 216, 216);
}

.values {
  background-color: #0B2048;
  text-align: center;
  width: 32%;
  height: 250px;
  padding: 10px 23px;
  border-radius: 30px;
}
.values img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 0.5px;
}
.values h3{
  font-size: 28px;
  color: white;
  margin: 8px 0;
}
.values p{
  font-size: 15px;
  color: rgb(218, 216, 216);
}



.carousel {
  width: 80%;
  max-width: 800px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.carouselimages {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carouselimages img {
  width: 100%;
  flex-shrink: 0;
}

.carouselcontrols {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carouselcontrols button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.carouselcontrols button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* @media screen and (max-width: 768px) {
.title h1 {
    font-size: 20px;
}
.ownerinfo h3 {
    font-size: 14px;
}
} */


@media (min-width : 1040px) and (max-width: 1200px) {
.title h1 {
    font-size: 1.5rem;
}
.owner{
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  padding: 15px 70px;
  gap: 30px;
  align-items: center;
 
}
.ownerimg{
  width: 450px ;
  height: 480px;
  border-radius: 30px;
  border: 5px solid white;
  background-color: white;
  padding: 3px;
  margin: 20px 40px;
}
.ownerimg img{
  width: 450px ;
  height: 480px;
  border-radius: 30px;
  border: 5px solid white;


}
.ownerimg:hover{
  width: 460px ;
  height: 490px;
  border-radius: 30px;
  box-shadow: 2px 5px 5px 5px rgb(3, 3, 3);
  

}
.ownerinfo{
width: 630px;
height: 400px;
overflow: hidden;
justify-content: center;
right: -80px;
margin-top: 0px;

}
.ownerinfo h3 {
    font-size: 1.5rem;
}
.ownerinfo h3 span {
  font-size: 1.6rem;
}
.carouselcontainer {
width: 100%;
height: 550px;
max-width: 70%;
margin: 30px auto;
overflow: hidden;
position: relative;
border-radius: 10px;
text-align: center;
align-items: center;
}

.carouselcontainer h1 {
    font-size: 3.5rem;
    font-weight: 800;
}

.purpose {
  display: inline-flex;
  gap: 20px;
  margin: 40px auto;
}
.visionmission{
  padding: 30px 100px;
  margin: 30px 0px;
  text-align: center;
  width: 100%;
}

.visionmission h1{
  font-size: 45px;
  font-weight: 800;
  color: black;
  margin: 30px 0;
}

.vision{
  width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}
.vision img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -48px;
  background-color: #0B2048;
  padding: 8px;
 }
.mission{
  width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}
.mission img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -48px;
  background-color: #0B2048;
  padding: 8px;
 }
.values {
    width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}

.values img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -48px;
  background-color: #0B2048;
  padding: 8px;
 }
.bannerimg {
    height: 60vh;
}
}

@media (min-width : 900px) and (max-width: 1040px) {
.title h1 {
    font-size: 1.5rem;
}
.owner{
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  padding: 15px 70px;
  gap: 30px;
  align-items: center;
 
}
.ownerimg{
  width: 430px ;
  height: 450px;
  border-radius: 30px;
  border: 5px solid white;
  background-color: white;
  padding: 3px;
  margin: 20px 30px;
}
.ownerimg img{
  width: 430px ;
  height: 450px;
  border-radius: 30px;
  border: 5px solid white;


}
.ownerimg:hover{
  width: 440px ;
  height: 460px;
  border-radius: 30px;
  box-shadow: 2px 5px 5px 5px rgb(3, 3, 3);
  

}
.ownerinfo{
width: 630px;
height: 400px;
overflow: hidden;
justify-content: center;
right: -80px;
margin-top: 0px;

}
.ownerinfo h3 {
    font-size: 1.3rem;
}
.ownerinfo h3 span {
  font-size: 1.4rem;
}
.carouselcontainer {
width: 100%;
height: 550px;
max-width: 70%;
margin: 30px auto;
overflow: hidden;
position: relative;
border-radius: 10px;
text-align: center;
align-items: center;
}

.carouselcontainer h1 {
    font-size: 3rem;
    font-weight: 800;
    margin: 20px 0;
}

.purpose {
  display: inline-flex;
  gap: 20px;
  margin: 40px auto;
}
.visionmission{
  padding: 30px 50px;
  margin: 30px 0px;
  text-align: center;
  width: 100%;
}

.visionmission h1{
  font-size: 45px;
  font-weight: 800;
  color: black;
  margin: 30px 0;
}

.vision{
  width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}
.vision img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -48px;
  background-color: #0B2048;
  padding: 8px;
 }
.mission{
  width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}
.mission img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -48px;
  background-color: #0B2048;
  padding: 8px;
 }
.values {
    width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}

.values img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -48px;
  background-color: #0B2048;
  padding: 8px;
 }
.bannerimg {
    height: 60vh;
}
}
@media (min-width :768px) and (max-width: 900px) {
.title h1 {
    font-size: 1.5rem;
}
.owner{
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  padding: 15px 50px;
  gap: 30px;
  align-items: center;
 
}
.ownerimg{
  width: 280px ;
  height: 350px;
  border-radius: 30px;
  border: 5px solid white;
  background-color: white;
  padding: 3px;
  margin: 20px 10px;
}
.ownerimg img{
  width: 280px ;
  height: 350px;
  border-radius: 30px;
  border: 5px solid white;


}
.ownerimg:hover{
  width: 250px ;
  height: 380px;
  border-radius: 30px;
  box-shadow: 2px 5px 5px 5px rgb(3, 3, 3);
  

}
.ownerinfo{
width: 630px;
height: 400px;
overflow: hidden;
justify-content: center;
right: -80px;
margin-top: 30px;

}
.ownerinfo h3 {
    font-size: 1.3rem;
}
.ownerinfo h3 span {
  font-size: 1.4rem;
}
.carouselcontainer {
width: 100%;
height: 550px;
max-width: 74%;
margin: 30px auto;
overflow: hidden;
position: relative;
border-radius: 10px;
text-align: center;
align-items: center;
}

.carouselcontainer h1 {
    font-size: 3rem;
    font-weight: 800;
    margin: 20px 0;
}

.purpose {
    flex-direction: column;
}
.visionmission{
  padding: 30px 130px;
  margin: 30px 0px;
  text-align: center;
  width: 100%;
}

.visionmission h1{
  font-size: 38px;
  font-weight: 800;
  color: black;
  margin: 30px 0;
}

.vision{
  width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}
.vision img{
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.mission{
  width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}
.mission img{
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.values {
    width: 100%;
    max-width: none;
    margin: 25px 0;
    height: auto;
}

.values img{
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.bannerimg {
    height: 60vh;
}
.values p{
  font-size: 22px;
  color: rgb(218, 216, 216);
}
.vision p{
font-size: 22px;
color: rgb(218, 216, 216);
}
.mission p{
font-size: 22px;
color: rgb(218, 216, 216);
}
}

@media (min-width : 650px) and (max-width: 768px) {
.title h1 {
    font-size: 1.5rem;
}
.owner{
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  padding: 15px 50px;
  gap: 30px;
  align-items: center;
 
}
.ownerimg{
  width: 280px ;
  height: 350px;
  border-radius: 30px;
  border: 5px solid white;
  background-color: white;
  padding: 3px;
  margin: 20px 10px;
}
.ownerimg img{
  width: 280px ;
  height: 350px;
  border-radius: 30px;
  border: 5px solid white;


}
.ownerimg:hover{
  width: 250px ;
  height: 380px;
  border-radius: 30px;
  box-shadow: 2px 5px 5px 5px rgb(3, 3, 3);
  

}
.ownerinfo{
width: 630px;
height: 400px;
overflow: hidden;
justify-content: center;
right: -80px;
margin-top: 30px;

}
.ownerinfo h3 {
    font-size: 0.9rem;
}
.ownerinfo h3 span {
  font-size: 1.3rem;
}
.carouselcontainer {
width: 100%;
height: 350px;
max-width: 60%;
margin: 30px auto;
overflow: hidden;
position: relative;
border-radius: 10px;
text-align: center;
align-items: center;
}

.carouselcontainer h1 {
    font-size: 1.8rem;
}

.purpose {
    flex-direction: column;
}
.visionmission{
  padding: 30px 130px;
  margin: 30px 0px;
  text-align: center;
  width: 100%;
}

.visionmission h1{
  font-size: 38px;
  font-weight: 800;
  color: black;
  margin: 30px 0;
}

.vision{
  width: 100%;
    max-width: none;
    margin: 25px 0;
}
.vision img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.mission{
  width: 100%;
    max-width: none;
    margin: 25px 0;
}
.mission img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.values {
    width: 100%;
    max-width: none;
    margin: 25px 0;
}

.values img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.bannerimg {
    height: 60vh;
}
}
@media (min-width : 480px) and (max-width: 650px) {
.title h1 {
    font-size: 1.5rem;
}
.owner{
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  padding: 15px 50px;
  gap: 30px;
  align-items: center;
 
}
.ownerimg{
  width: 230px ;
  height: 300px;
  border-radius: 30px;
  border: 5px solid white;
  background-color: white;
  padding: 3px;
  margin: 20px 10px;
}
.ownerimg img{
  width: 230px ;
  height: 300px;
  border-radius: 30px;
  border: 5px solid white;


}
.ownerimg:hover{
  width: 230px ;
  height: 300px;
  border-radius: 30px;
  box-shadow: 2px 5px 5px 5px rgb(3, 3, 3);
  

}
.ownerinfo{
width: 630px;
height: 400px;
overflow: hidden;
justify-content: center;
right: -80px;
margin-top: 50px;

}
.ownerinfo h3 {
    font-size: 0.7rem;
}
.ownerinfo h3 span {
  font-size: 1rem;
}
.carouselcontainer {
width: 100%;
height: 280px;
max-width: 60%;
margin: 30px auto;
overflow: hidden;
position: relative;
border-radius: 10px;
text-align: center;
align-items: center;
}

.carouselcontainer h1 {
    font-size: 1.8rem;
}

.purpose {
    flex-direction: column;
}
.visionmission{
  padding: 30px 130px;
  margin: 30px 0px;
  text-align: center;
  width: 100%;
}

.visionmission h1{
  font-size: 28px;
  font-weight: 800;
  color: black;
  margin: 20px 0;
}

.vision{
  width: 100%;
  height: auto;
    max-width: none;
    margin: 25px 0;
    justify-content: center;
    
}
.vision img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.mission{
  width: 100%;
    max-width: none;
    margin: 25px 0;
}
.mission img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.values {
    width: 100%;
    max-width: none;
    margin: 25px 0;
}

.values img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.bannerimg {
    height: 60vh;
}
}

@media (min-width : 320px) and (max-width: 480px) {
.banner{
  width: 100%;
  height: 35vh;
 
}

.bannerimg {
  width: 100%;
  height: 80vh;
  margin: 0;
  padding: 0;
  
}

.title h1 {
    font-size: 1.5rem;
}
.owner{
  width: 100%;
  height: auto;
  display: block;
  
  padding: 15px 30px;
  gap: 30px;
  align-items: center;
 
}
.ownerimg{
  width: 95% ;
  height: 300px;
  border-radius: 30px;
  border: 5px solid white;
  background-color: white;
  padding: 0px;
  margin: 20px 10px;
}
.ownerimg img{
  width: 100% ;
  height: 300px;
  border-radius: 30px;
  border: 5px solid white;


}
.ownerimg:hover{
  width: 230px ;
  height: 300px;
  border-radius: 30px;
  box-shadow: 2px 5px 5px 5px rgb(3, 3, 3);
  

}
.ownerinfo{
width: 100%;
height: auto;
overflow: hidden;
justify-content: center;
right: -80px;
margin-top: 30px;

}
.ownerinfo h3 {
    font-size: 0.7rem;
}
.ownerinfo h3 span {
  font-size: 1rem;
}
.carouselcontainer {
width: 100%;
height: 300px;
max-width: 100%;
margin: 30px auto;
overflow: hidden;
position: relative;
border-radius: 10px;
text-align: center;
align-items: center;
}

.carouselcontainer h1 {
    font-size: 1.8rem;
}

.purpose {
    flex-direction: column;
}
.visionmission{
  padding: 30px 20px;
  margin: 30px 0px;
  text-align: center;
  width: 100%;
}

.visionmission h1{
  font-size: 24px;
  font-weight: 800;
  color: black;
  margin: 20px 0;
}

.vision{
  width: 100%;
  height: auto;
    max-width: none;
    margin: 25px 0;
    justify-content: center;
    
}
.vision img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.mission{
  width: 100%;
    max-width: none;
    margin: 25px 0;
}
.mission img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.values {
    width: 100%;
    max-width: none;
    margin: 25px 0;
}

.values img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.bannerimg {
    height: 60vh;
}
}
@media (min-width: 130px) and (max-width: 320px) {
*{
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.banner{
  width: 100%;
  height: 35vh;
 
}

.bannerimg {
  width: 100%;
  height: 80vh;
  margin: 0;
  padding: 0;
  
}

.title h1 {
    font-size: 1.5rem;
}
.owner{
  width: 100%;
  height:auto;
  display: block;
  
  padding: 15px 30px;
  gap: 30px;
  align-items: center;
 
}
.ownerimg{
  width: 95% ;
  height: 300px;
  border-radius: 30px;
  border: 5px solid white;
  background-color: white;
  padding: 0px;
  margin: 20px 10px;
}
.ownerimg img{
  width: 100% ;
  height: 300px;
  border-radius: 30px;
  border: 5px solid white;


}
.ownerimg:hover{
  width: 230px ;
  height: 300px;
  border-radius: 30px;
  box-shadow: 2px 5px 5px 5px rgb(3, 3, 3);
  

}
.ownerinfo{
width: 100%;
height: auto;
overflow: hidden;
justify-content: center;
right: -80px;
margin-top: 30px;

}
.ownerinfo h3 {
    font-size: 0.7rem;
}
.ownerinfo h3 span {
  font-size: 1rem;
}
.carouselcontainer {
width: 100%;
height: 300px;
max-width: 100%;
margin: 30px auto;
overflow: hidden;
position: relative;
border-radius: 10px;
text-align: center;
align-items: center;
}

.carouselcontainer h1 {
    font-size: 1.8rem;
}

.purpose {
    flex-direction: column;
}
.visionmission{
  padding: 30px 20px;
  margin: 30px 0px;
  text-align: center;
  width: 100%;
}

.visionmission h1{
  font-size: 24px;
  font-weight: 800;
  color: black;
  margin: 20px 0;
}

.vision{
  width: 100%;
  height: auto;
    max-width: none;
    margin: 25px 0;
    justify-content: center;
    
}
.vision img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.mission{
  width: 100%;
    max-width: none;
    margin: 25px 0;
}
.mission img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.values {
    width: 100%;
    max-width: none;
    margin: 25px 0;
}

.values img{
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: -45px;
  background-color: #0B2048;
  padding: 8px;
 }
.bannerimg {
    height: 60vh;
}
}