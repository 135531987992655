* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.banner img {
    width: 100%;
    height: 80vh;
    margin: 0;
}

.internship-banner {
    background-image: url(../assets/background.jpg);
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: 70% 100%;
    filter: brightness(40%);
    background-color: #eeeef2;
    position: relative;
}

.banner-text {
    color: white;
    width: 700px;
    text-align: center;
    top: 200px;
    right: 50px;
    position: absolute;
}

.banner-text h1 {
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 30px;
    color: rgb(249, 248, 251);
}

.banner-text h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 1.4
}

.banner-text h5 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 1.4
}

.internship-btn {
    padding: 15px 50px;
    margin: 20px 0;
    border: none;
    border-radius: 20px;
    background-color: #102842;

}

button .btn-link {
    color: white;
    text-decoration: none;
    font-size: 20px;
}

button .btn-link:hover {

    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.intern {
    width: 90%;
    margin: 40px 60px;
    padding: 40px 30px;
    display: inline-flex;
    gap: 80px;
    background-color: rgb(231, 247, 246);
    border-radius: 30px;
    text-align: left;
}

/* #carouselExampleRide {
    width: 450px;
    height: 450px;
}
.carusel-image-box{
   
    height: 100%;
    margin: 15px 50px;
    border-radius: 30px;
}
.carusel-img{
    width: 100%;
    height: 500px;
 
    border-radius: 30px;
} */

.carousel-container {
    width: 60%;
    max-width: 450px;
    height: 400px;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Slides wrapper */
.carousel-slides {
    display: flex;
    width:400px;
    transition: transform 0.45s ease;
}

/* Individual slide */
.carousel-slide {
    flex: 1 0 100%;
}

/* Images */
.carousel-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

/* Animation */

    @keyframes slide {
        0% {
            transform: translateX(0%);
        }

        20% {
            transform: translateX(-100%);
        }

        35% {
            transform: translateX(-200%);
        }

        50% {
            transform: translateX(-200%);
        }

        60% {
            transform: translateX(-300%);
        }

        70% {
            transform: translateX(-300%);
        }

        80% {
            transform: translateX(-400%);
        }

        90% {
            transform: translateX(-400%);
        }

        /* 95% {
            transform: translateX(-550%);
        } */

        100% {
            transform: translateX(0%);
        }
    }

.our-intern {
    width: 50%;
    margin: 80px 10px;
    padding: 10px 40px;
}

.our-intern h1 {
    font-size: 50px;
    font-weight: 700;
    color: #102842;
    margin-bottom: 40px;
}

.our-intern h2 {
    font-size: 20px;
    font-weight: 500;
    color: black;
    line-height: 1.5;
}

.join-us {
    margin: 20px 0;
    text-align: center;
    background-color: #102842;
    color: white;
    padding: 60px 70px;

}

.join-us h1 {
    font-size: 45px;
    font-weight: 700;
    margin: 10px 0 50px;

}

.join-us-box {
    display: flex;
    gap: 40px;
    align-items: center;
    margin: 30px;
}

.join-us-box img {
    width: 45%;
    height: 350px;
    border-radius: 20px;
}

.join-us-text {
    width: 700px;
}

.join-us-text h2 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
}

.join-us-text h3 {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
}
/* Applying the animation */
.carousel-slides {
    animation: slide 10s infinite;
}

.our-intern {
    width: 100%;
    margin: 30px 5px;
    padding: 10px 15px;
}

.our-intern h1 {
    font-size: 40px;
    font-weight: 700;
    color: #102842;
    margin-bottom: 30px;
}

.our-intern h2 {
    font-size: 20px;
    font-weight: 500;
    color: black;
    line-height: 1.5;
}

.join-us {
    margin: 20px 0;
    text-align: center;
    background-color: #102842;
    color: white;
    padding: 60px 10px;

}

.join-us h1 {
    font-size: 28px;
    font-weight: 700;
    margin: 10px 0 50px;

}

.join-us-box {
    display: block;
    gap: 20px;
    width: 90%;
    align-items: center;
    margin: 50px 20px;
    border: 1px solid #24415f;
    padding: 2px;
    border-radius: 20px;
}

.join-us-box img {
    width: 100%;
    height: 320px;
    border-radius: 20px;
}

.join-us-text {
    width: 100%;
    margin: 30px 0;
}

.join-us-text h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
}

.join-us-text h3 {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
}
@media (max-width:1440px)and (min-width:1024px) {
    .banner img {
        width: 100%;
        height: 60vh;
        margin: 0;
    }

    .internship-banner {
        background-image: url(../assets/background.jpg);
        width: 100%;
        height: 60vh;
        background-repeat: no-repeat;
        background-size: 70% 100%;
        filter: brightness(40%);
        background-color: #eeeef2;
        position: relative;
    }

    .banner-text {
        color: white;
        width: 700px;
        text-align: center;
        top: 200px;
        right: 80px;
        position: absolute;
    }

    .banner-text h1 {
        font-size: 50px;
        font-weight: 900;
        margin-bottom: 30px;
        color: rgb(249, 248, 251);
    }

    .banner-text h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 1.4
    }

    .banner-text h5 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 1.4
    }

    .internship-btn {
        padding: 15px 50px;
        margin: 20px 0;
        border: none;
        border-radius: 20px;
        background-color: #102842;

    }

    button .btn-link {
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    button .btn-link:hover {

        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    .intern {
        width: 90%;
        margin: 40px 60px;
        padding: 40px 30px;
        display: inline-flex;
        gap: 80px;
        background-color: rgb(231, 247, 246);
        border-radius: 30px;
        text-align: left;
    }

    /* #carouselExampleRide {
        width: 450px;
        height: 450px;
    }
    .carusel-image-box{
       
        height: 100%;
        margin: 15px 50px;
        border-radius: 30px;
    }
    .carusel-img{
        width: 100%;
        height: 500px;
     
        border-radius: 30px;
    } */

    .carousel-container {
        width: 450px;
        height: 450px;
        max-width: 600px;
        margin: auto;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
    }

    /* Slide wrapper */
    .carousel-slides {
        display: flex;
        transition: transform 0.45s ease;
        width: 100%;
        gap: 20px;
    }

    /* Each slide */
    .carousel-slide {
        min-width: 100%;
    }

    /* Image style */
    .carousel-slide img {
        width: 100%;
        height: 500px;
        border-radius: 30px;
        display: block;
    }

    /* Animation */
    @keyframes slide {
        0% {
            transform: translateX(0%);
        }

        20% {
            transform: translateX(-100%);
        }

        35% {
            transform: translateX(-200%);
        }

        50% {
            transform: translateX(-200%);
        }

        60% {
            transform: translateX(-300%);
        }

        70% {
            transform: translateX(-400%);
        }

        80% {
            transform: translateX(-500%);
        }

        90% {
            transform: translateX(-600%);
        }

        95% {
            transform: translateX(-700%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    /* Applying the animation */
    .carousel-slides {
        animation: slide 10s infinite;
    }

    .our-intern {
        width: 50%;
        margin: 80px 10px;
        padding: 10px 40px;
    }

    .our-intern h1 {
        font-size: 50px;
        font-weight: 700;
        color: #102842;
        margin-bottom: 40px;
    }

    .our-intern h2 {
        font-size: 20px;
        font-weight: 500;
        color: black;
        line-height: 1.5;
    }

    .join-us {
        margin: 20px 0;
        text-align: center;
        background-color: #102842;
        color: white;
        padding: 60px 70px;

    }

    .join-us h1 {
        font-size: 45px;
        font-weight: 700;
        margin: 10px 0 50px;

    }

    .join-us-box {
        display: flex;
        gap: 40px;
        align-items: center;
        margin: 30px;
    }

    .join-us-box img {
        width: 45%;
        height: 350px;
        border-radius: 20px;
    }

    .join-us-text {
        width: 700px;
    }

    .join-us-text h2 {
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .join-us-text h3 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
}


@media (max-width:1024px) and (min-width:768px) {
    .banner img {
        width: 100%;
        height: auto;
        margin: 0;
    }

    .internship-banner {
        background-image: url(../assets/background.jpg);
        width: 100%;
        height: auto;
        min-height: 65vh;
        background-repeat: no-repeat;
        background-size: 70% 100%;
        filter: brightness(40%);
        background-color: #eeeef2;
        position: relative;
    }

    .banner-text {
        color: white;
        width: 550px;
        text-align: center;
        top: 35%;
        right: 40px;
        position: absolute;
    }

    .banner-text h1 {
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 30px;
        color: rgb(249, 248, 251);
    }

    .banner-text h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 1.4
    }

    .banner-text h5 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 1.4
    }

    .internship-btn {
        padding: 15px 50px;
        margin: 20px 0;
        border: none;
        border-radius: 20px;
        background-color: #102842;

    }

    button .btn-link {
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    button .btn-link:hover {

        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    .intern {
        width: 90%;
        margin: 40px 60px;
        padding: 40px 30px;
        display: inline-flex;
        gap: 80px;
        background-color: rgb(231, 247, 246);
        border-radius: 30px;
        text-align: left;
    }

    /* #carouselExampleRide {
        width: 450px;
        height: 450px;
    }
    .carusel-image-box{
       
        height: 100%;
        margin: 15px 50px;
        border-radius: 30px;
    }
    .carusel-img{
        width: 100%;
        height: 500px;
     
        border-radius: 30px;
    } */

    .carousel-container {
        width: 450px;
        height: 450px;
        max-width: 600px;
        margin: auto;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
    }

    /* Slide wrapper */
    .carousel-slides {
        display: flex;
        transition: transform 0.45s ease;
        width: 100%;
        gap: 20px;
    }

    /* Each slide */
    .carousel-slide {
        min-width: 100%;
    }

    /* Image style */
    .carousel-slide img {
        width: 100%;
        height: 500px;
        border-radius: 30px;
        display: block;
    }

    /* Animation */
    @keyframes slide {
        0% {
            transform: translateX(0%);
        }

        20% {
            transform: translateX(-100%);
        }

        35% {
            transform: translateX(-200%);
        }

        50% {
            transform: translateX(-200%);
        }

        60% {
            transform: translateX(-300%);
        }

        70% {
            transform: translateX(-400%);
        }

        80% {
            transform: translateX(-500%);
        }

        90% {
            transform: translateX(-600%);
        }

        95% {
            transform: translateX(-700%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    /* Applying the animation */
    .carousel-slides {
        animation: slide 10s infinite;
    }

    .our-intern {
        width: 50%;
        margin: 80px 10px;
        padding: 10px 40px;
    }

    .our-intern h1 {
        font-size: 50px;
        font-weight: 700;
        color: #102842;
        margin-bottom: 40px;
    }

    .our-intern h2 {
        font-size: 20px;
        font-weight: 500;
        color: black;
        line-height: 1.5;
    }

    .join-us {
        margin: 20px 0;
        text-align: center;
        background-color: #102842;
        color: white;
        padding: 60px 40px;

    }

    .join-us h1 {
        font-size: 45px;
        font-weight: 700;
        margin: 10px 0 50px;

    }

    .join-us-box {
        display: flex;
        gap: 30px;
        align-items: center;
        margin: 20px;
    }

    .join-us-box img {
        width: 48%;
        height: 350px;
        border-radius: 20px;
    }

    .join-us-text {
        width: 520px;
    }

    .join-us-text h2 {
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .join-us-text h3 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
}

@media (max-width:768px) and (min-width:425px) {
    .banner img {
        width: 100%;
        height: auto;
        margin: 0;
    }

    .internship-banner {
        background-image: url(../assets/background.jpg);
        width: 100%;
        height: 60vh;
        background-repeat: no-repeat;
        background-size: 70% 100%;
        filter: brightness(40%);
        background-color: #eeeef2;
        position: relative;
    }

    .banner-text {
        color: white;
        width: 58%;
        text-align: center;
        top: 35%;
        right: 20px;
        position: absolute;
    }

    .banner-text h1 {
        font-size: 30px;
        font-weight: 900;
        margin-bottom: 10px;
        color: rgb(249, 248, 251);
    }

    .banner-text h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 1.4
    }

    .banner-text h5 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 1.4
    }

    .internship-btn {
        padding: 15px 50px;
        margin: 20px 0;
        border: none;
        border-radius: 20px;
        background-color: #102842;

    }

    button .btn-link {
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    button .btn-link:hover {

        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    .intern {
        width: 90%;
        margin: 20px 38px;
        padding: 50px 30px;
        display: block;
        gap: 80px;
        background-color: rgb(231, 247, 246);
        border-radius: 30px;
        text-align: left;
    }

    /* #carouselExampleRide {
        width: 450px;
        height: 450px;
    }
    .carusel-image-box{
       
        height: 100%;
        margin: 15px 50px;
        border-radius: 30px;
    }
    .carusel-img{
        width: 100%;
        height: 500px;
     
        border-radius: 30px;
    } */

    .carousel-container {
        width: 450px;
        height: 500px;
        max-width: 600px;
        margin: auto;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
    }

    /* Slide wrapper */
    .carousel-slides {
        display: flex;
        transition: transform 0.45s ease;
        width: 100%;
        gap: 20px;
    }

    /* Each slide */
    .carousel-slide {
        min-width: 100%;
    }

    /* Image style */
    .carousel-slide img {
        width: 100%;
        height: 500px;
        border-radius: 30px;
        display: block;
    }

    /* Animation */
    @keyframes slide {
        0% {
            transform: translateX(0%);
        }

        20% {
            transform: translateX(-100%);
        }

        35% {
            transform: translateX(-200%);
        }

        50% {
            transform: translateX(-200%);
        }

        60% {
            transform: translateX(-300%);
        }

        70% {
            transform: translateX(-400%);
        }

        80% {
            transform: translateX(-500%);
        }

        90% {
            transform: translateX(-600%);
        }

        95% {
            transform: translateX(-700%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    /* Applying the animation */
    .carousel-slides {
        animation: slide 10s infinite;
    }

    .our-intern {
        width: 100%;
        margin: 30px 5px;
        padding: 10px 40px;
    }

    .our-intern h1 {
        font-size: 50px;
        font-weight: 700;
        color: #102842;
        margin-bottom: 40px;
    }

    .our-intern h2 {
        font-size: 20px;
        font-weight: 500;
        color: black;
        line-height: 1.5;
    }

    .join-us {
        margin: 20px 0;
        text-align: center;
        background-color: #102842;
        color: white;
        padding: 60px 20px;

    }

    .join-us h1 {
        font-size: 45px;
        font-weight: 700;
        margin: 10px 0 50px;

    }

    .join-us-box {
        display: flex;
        gap: 20px;
        align-items: center;
        margin: 50px 20px;
    }

    .join-us-box img {
        width: 50%;
        height: 320px;
        border-radius: 20px;
    }

    .join-us-text {
        width: 44%;
    }

    .join-us-text h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .join-us-text h3 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
}

@media (max-width:425px) and (min-width:375px) {
    .banner img {
        width: 100%;
        height: 40vh;
        margin: 0;
    }

    .internship-banner {
        background-image: url(../assets/background.jpg);
        width: 100%;
        height: 60vh;
        background-repeat: no-repeat;
        background-size: 70% 100%;
        filter: brightness(40%);
        background-color: #eeeef2;
        position: relative;
    }

    .banner-text {
        color: white;
        width: 92%;
        text-align: center;
        top: 140px;
        right: 20px;
        position: absolute;
    }

    .banner-text h1 {
        font-size: 25px;
        font-weight: 900;
        margin-bottom: 7px;
        color: rgb(249, 248, 251);
    }

    .banner-text h3 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 1.2
    }

    .banner-text h5 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 1.2
    }

    .internship-btn {
        padding: 15px 50px;
        margin: 20px 0;
        border: none;
        border-radius: 20px;
        background-color: #102842;

    }

    button .btn-link {
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    button .btn-link:hover {

        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    .intern {
        width: 90%;
        margin: 20px 22px;
        padding: 50px 20px;
        display: block;
        gap: 80px;
        background-color: rgb(231, 247, 246);
        border-radius: 30px;
        text-align: left;
    }

    /* #carouselExampleRide {
        width: 450px;
        height: 450px;
    }
    .carusel-image-box{
       
        height: 100%;
        margin: 15px 50px;
        border-radius: 30px;
    }
    .carusel-img{
        width: 100%;
        height: 500px;
     
        border-radius: 30px;
    } */

    .carousel-container {
        width: 90%;
        height: 500px;
        max-width: 90%;
        margin: 15px;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
    }

    /* Slide wrapper */
    .carousel-slides {
        display: flex;
        transition: transform 0.45s ease;
        width: 100%;
        gap: 20px;
    }

    /* Each slide */
    .carousel-slide {
        min-width: 100%;
    }

    /* Image style */
    .carousel-slide img {
        width: 100%;
        height: 500px;
        border-radius: 30px;
        display: block;
    }

    /* Animation */
    @keyframes slide {
        0% {
            transform: translateX(0%);
        }

        20% {
            transform: translateX(-100%);
        }

        35% {
            transform: translateX(-200%);
        }

        50% {
            transform: translateX(-200%);
        }

        60% {
            transform: translateX(-300%);
        }

        70% {
            transform: translateX(-400%);
        }

        80% {
            transform: translateX(-500%);
        }

        90% {
            transform: translateX(-600%);
        }

        95% {
            transform: translateX(-700%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    /* Applying the animation */
    .carousel-slides {
        animation: slide 10s infinite;
    }

    .our-intern {
        width: 100%;
        margin: 30px 5px;
        padding: 10px 15px;
    }

    .our-intern h1 {
        font-size: 40px;
        font-weight: 700;
        color: #102842;
        margin-bottom: 30px;
    }

    .our-intern h2 {
        font-size: 20px;
        font-weight: 500;
        color: black;
        line-height: 1.5;
    }

    .join-us {
        margin: 20px 0;
        text-align: center;
        background-color: #102842;
        color: white;
        padding: 60px 20px;

    }

    .join-us h1 {
        font-size: 28px;
        font-weight: 700;
        margin: 10px 0 50px;

    }

    .join-us-box {
        display: block;
        gap: 20px;
        width: 90%;
        align-items: center;
        margin: 50px 20px;
        border: 1px solid #24415f;
        padding: 2px;
        border-radius: 20px;
    }

    .join-us-box img {
        width: 100%;
        height: 320px;
        border-radius: 20px;
    }

    .join-us-text {
        width: 100%;
        margin: 30px 0;
    }

    .join-us-text h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .join-us-text h3 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
}

@media (max-width:375px) and (min-width:320px) {
    .banner img {
        width: 100%;
        height: 60vh;
        margin: 0;
    }

    .internship-banner {
        background-image: url(../assets/background.jpg);
        width: 100%;
        height: 60vh;
        background-repeat: no-repeat;
        background-size: 70% 100%;
        filter: brightness(40%);
        background-color: #eeeef2;
        position: relative;
    }

    .banner-text {
        color: white;
        width: 90%;
        text-align: center;
        top: 340px;
        right: 20px;
        position: absolute;
    }

    .banner-text h1 {
        font-size: 22px;
        font-weight: 900;
        margin-bottom: 10px;
        color: rgb(249, 248, 251);
    }

    .banner-text h3 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 1.2
    }

    .banner-text h5 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 1.2
    }

    .internship-btn {
        padding: 15px 50px;
        margin: 20px 0;
        border: none;
        border-radius: 20px;
        background-color: #102842;

    }

    button .btn-link {
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    button .btn-link:hover {

        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    .intern {
        width: 90%;
        margin: 20px 22px;
        padding: 50px 20px;
        display: block;
        gap: 80px;
        background-color: rgb(231, 247, 246);
        border-radius: 30px;
        text-align: left;
    }

    /* #carouselExampleRide {
        width: 450px;
        height: 450px;
    }
    .carusel-image-box{
       
        height: 100%;
        margin: 15px 50px;
        border-radius: 30px;
    }
    .carusel-img{
        width: 100%;
        height: 500px;
     
        border-radius: 30px;
    } */

    .carousel-container {
        width: 90%;
        height: 500px;
        max-width: 90%;
        margin: 15px;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
    }

    /* Slide wrapper */
    .carousel-slides {
        display: flex;
        transition: transform 0.15s ease;
        width: 100%;
        gap: 20px;
    }

    /* Each slide */
    .carousel-slide {
        min-width: 100%;
    }

    /* Image style */
    .carousel-slide img {
        width: 100%;
        height: 500px;
        border-radius: 30px;
        display: block;
    }

    /* Animation */
    @keyframes slide {
        0% {
            transform: translateX(0%);
        }

        20% {
            transform: translateX(-100%);
        }

        35% {
            transform: translateX(-200%);
        }

        50% {
            transform: translateX(-200%);
        }

        60% {
            transform: translateX(-300%);
        }

        70% {
            transform: translateX(-400%);
        }

        80% {
            transform: translateX(-500%);
        }

        90% {
            transform: translateX(-600%);
        }

        95% {
            transform: translateX(-700%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    /* Applying the animation */
    .carousel-slides {
        animation: slide 10s infinite;
    }

    .our-intern {
        width: 100%;
        margin: 30px 5px;
        padding: 10px 15px;
    }

    .our-intern h1 {
        font-size: 40px;
        font-weight: 700;
        color: #102842;
        margin-bottom: 30px;
    }

    .our-intern h2 {
        font-size: 20px;
        font-weight: 500;
        color: black;
        line-height: 1.5;
    }

    .join-us {
        margin: 20px 0;
        text-align: center;
        background-color: #102842;
        color: white;
        padding: 60px 20px;

    }

    .join-us h1 {
        font-size: 28px;
        font-weight: 700;
        margin: 10px 0 50px;

    }

    .join-us-box {
        display: block;
        gap: 20px;
        width: 90%;
        align-items: center;
        margin: 50px 20px;
        border: 1px solid #24415f;
        padding: 2px;
        border-radius: 20px;
    }

    .join-us-box img {
        width: 100%;
        height: 320px;
        border-radius: 20px;
    }

    .join-us-text {
        width: 100%;
        margin: 30px 0;
    }

    .join-us-text h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .join-us-text h3 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
}




@media (max-width:320px) and (min-width:200px) {
    .banner img {
        width: 100%;
        height: 80vh;
        margin: 0;
    }

    .internship-banner {
        background-image: url(../assets/background.jpg);
        width: 100%;
        height: 80vh;
        background-repeat: no-repeat;
        background-size: 80% 100%;
        filter: brightness(40%);
        background-color: #eeeef2;
        position: relative;
    }

    .banner-text {
        color: white;
        width: 90%;
        text-align: center;
        top: 320px;
        right: 20px;
        position: absolute;
    }

    .banner-text h1 {
        font-size: 22px;
        font-weight: 900;
        margin-bottom: 10px;
        color: rgb(249, 248, 251);
    }

    .banner-text h3 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 1.2
    }

    .banner-text h5 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 1.2
    }

    .internship-btn {
        padding: 15px 50px;
        margin: 20px 0;
        border: none;
        border-radius: 20px;
        background-color: #102842;

    }

    button .btn-link {
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    button .btn-link:hover {

        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 20px;
    }

    .intern {
        width: 100%;
        margin: 20px 0;
        padding: 50px 20px;
        display: block;
        gap: 80px;
        background-color: rgb(231, 247, 246);
        border-radius: 30px;
        text-align: left;
    }

    /* #carouselExampleRide {
        width: 450px;
        height: 450px;
    }
    .carusel-image-box{
       
        height: 100%;
        margin: 15px 50px;
        border-radius: 30px;
    }
    .carusel-img{
        width: 100%;
        height: 500px;
     
        border-radius: 30px;
    } */

    .carousel-container {
        width: 92%;
        height: 400px;
        max-width: 90%;
        margin: 10px;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
    }

    /* Slide wrapper */
    .carousel-slides {
        display: flex;
        transition: transform 0.5s ease;
        width: 100%;
        gap: 20px;
    }

    /* Each slide */
    .carousel-slide {
        min-width: 100%;
    }

    /* Image style */
    .carousel-slide img {
        width: 100%;
        height: 500px;
        border-radius: 30px;
        display: block;
    }

    /* Animation */
    @keyframes slide {
        0% {
            transform: translateX(0%);
        }

        20% {
            transform: translateX(-100%);
        }

        35% {
            transform: translateX(-200%);
        }

        50% {
            transform: translateX(-200%);
        }

        60% {
            transform: translateX(-300%);
        }

        70% {
            transform: translateX(-400%);
        }

        80% {
            transform: translateX(-500%);
        }

        90% {
            transform: translateX(-600%);
        }

        95% {
            transform: translateX(-700%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    /* 60% { transform: translateX(-300%); }
        80% { transform: translateX(-400%); }
        100% { transform: translateX(0%); } */
}