/* 404.css */
.container {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  .header {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .errorMessage {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .header {
      font-size: 18px;
    }
    .errorMessage {
      font-size: 18px;
    }
    .button {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .header {
      font-size: 16px;
    }
    .errorMessage {
      font-size: 16px;
    }
    .button {
      font-size: 12px;
    }
  }