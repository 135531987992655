.HeadingContainer{
    width: 100%;
    display: flex;
    background: rgb(244, 251, 254);
}

.ImgContainer{
    width: 50%;
}
.ImgContainer img{
    width: 100%;
}
.Heading{
    width: 50%;
    padding: 2%;
    text-align: start;
    font-size: 12px;
    color: rgb(85, 87, 87);
    font-weight: bold;
    font-family:serif;
}
.certificateText{
    text-align: center;
}

.certificateText h1{
    font-size: 35px;
    font-family: poppins;
    color: rgb(65, 67, 67);
    font-weight: bold;
}

.certificateText p{
    font-size: 25px;
    font-family: poppins;
    color: rgb(65, 67, 67);
    font-weight: lighter;
}
.certificateContainer{

    display: grid;
    width: 100%;
    grid-template-columns: repeat(4,24%);
    justify-content: space-evenly;
    align-items: stretch!important;
   place-items: center;
   row-gap: 15px;
   margin-bottom: 30px;
}
.certificateDiv{
    /* border: 3px dashed red; */
    width: 100%;
    text-align: center;
    /* align-items: stretch!important; */

    position: relative;
    border-radius: 20px;
    padding-bottom: 80px;
    /* padding: 5px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
.certificateDiv h1{
    font-size: 19px;
    font-family: poppins;
    color: rgb(65, 67, 67);
    font-weight: bold;
    margin-top: 10px;
}
.certificateBtn{
    display: flex;
    position: absolute;
    justify-content: space-between;
    /* padding: 15px; */
    margin-top: 30px;
    gap: 35px;
    bottom: 15px;
    left: 16%;
}
.certificateBtn button{
    /* width: 120px; */
    /* height: 30px; */
    padding: 10px;
    border-radius: 10px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    background:  #794dab;
}



@media screen and (max-width: 1250px) {
    .HeadingContainer{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        background: rgb(244, 251, 254);
    }
    
    .ImgContainer{
        width: 100%;
    }
    .ImgContainer img{
        width: 100%;
    }
    .Heading{
        width: 100%;
        /* padding: 2%; */
        text-align: start;
    }
    .certificateContainer{
        grid-template-columns: repeat(3,30%);
    }
    .certificateBtn{
        /* width: 60%; */
        left: 10%;
        bottom: 10px;
        gap: 10px;
    }
}



@media screen and (max-width: 900px) {
    .certificateContainer{
        grid-template-columns: repeat(2,47%);
    }
    .certificateDiv{
        padding-bottom: 5px;
    }
    .certificateBtn{
        width: 60%;
        left: 18%;
        flex-direction: column;
        position: relative;
        bottom: 0px;
        gap: 10px;
    }
}

@media screen and (max-width: 500px) {
    .certificateContainer{
        grid-template-columns: repeat(1,99%);
    
    }
    .certificateDiv{
        padding-bottom: 5px;
    }
    .certificateBtn{
        width: 60%;
        left: 18%;
        flex-direction: column;
        position: relative;
        bottom: 0px;
        gap: 10px;
    }
}