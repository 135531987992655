

.cloudBanner{
    position: relative;
    width: 100%;
    height: 500px;
    background-image: url("../assets/Bg1.webp");
    object-fit: cover;
    background-size: cover;
    background-attachment : fixed;
}

.cloudBanner h1{    
    position: absolute;
    bottom: 27%;
    left: 56%;
    color: rgb(207, 197, 197);
    font-size: 60px;
    font-family: poppins;
    font-weight: bold;
}

.cloudBanner .underline{
    position: absolute;
    bottom: 25%;
    left: 58%;
    width: 150px;
    height: 5px;
    background-color: rgb(156, 157, 165);
}
.bannerDown {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8A99B4;
  font-size: 40px;
}
.bannerDown h1{
  font-family: poppins;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 40px;
}
.courses{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4,23%);
    justify-content: space-between;
    align-items: stretch;
   place-items: center;
   row-gap: 15px;
   margin-bottom: 30px;
}
.course{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding:30px 15px;
}
.course img{
    width: 100%;
    height: 150px;
    display: block;
    object-fit: cover;
}
.course h1{
    font-size: 30px;
    font-family: poppins;
    color: rgb(0, 0, 0);
}

.courseBtn{
    width: 115px;
    height: 40px;
    background: #80669d;
    color: rgb(254, 254, 255);
    cursor: pointer;
    padding: 2px;
    border-radius: 5px;
    font-size: 18px;
    font-family: poppins;
    font-weight: lighter; 
    border: none;
}

.courseBtn:hover{

    background: rgb(0, 0, 0);
    color: white;
    transition: all 1.9s ease;
}

@media screen and (max-width: 1250px) {
    .courses{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3,30%);
        justify-content: space-between;
        align-items: stretch;
       place-items: center;
       row-gap: 15px;
       margin-bottom: 30px;
    }
}



@media screen and (max-width: 900px) {
    .courses{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2,47%);
        justify-content: space-between;
        align-items: stretch;
       place-items: center;
       row-gap: 15px;
       margin-bottom: 30px;
    }
}

@media screen and (max-width: 500px) {
    .courses{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1,99%);
        justify-content: space-between;
        align-items: stretch;
       place-items: center;
       row-gap: 15px;
       margin-bottom: 30px;
    }
    .cloudBanner h1{
        left: 20%;
    }
    .cloudBanner .underline{
        left: 25%;
    }
}