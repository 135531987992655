.FooterContainer {
    background: white;
    padding: 20px;
    /* background-image:  url(../assets/traningBg-2.jpg); */
    position: relative;
    background-image: url("../assets/traningBg-3.jpg");
    background-size: cover;
    /* Ensures the image covers the container */
    background-position: center;
    height: 600px;
    ;
    /* You can adjust this height as per your design */
    width: auto;
}



.Details {
    max-width: 80%;
    margin: 0 auto 50px;
    padding: 20px;
    height: 206px;
    display: flex;
    justify-content: space-around;
}

.detailsDiv {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
}

.address:hover {
    box-shadow: #faf8f8e7 0px 12px 25px, rgba(247, 243, 243, 0.938) 0px 15px 12px;
    border-radius: 10px;
    transition: box-shadow 0.6s ease;
}

.address {
    width: 350px;
    min-height: 206px;
    display: flex;
    align-items: center;
    border: 1px solid #8A99B4;
    padding: 10px;
    margin-bottom: 20px;
    justify-content: center;
    color: white;
}

.address .iconDiv {
    width: auto;
    min-height: 60px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
}

.iconDiv Link {
    color: blue;
    gap: 5px;
}

.icon {
    width: 30px;
    height: 80px;
    color: white;
}

.addressText {
    margin-left: 10px;
    padding: 5px;
    gap: 5px;
}

.address h1 {
    color: white;
    font-size: 20px;
    font-family: poppins;
    text-align: center;
    font-weight: lighter;
}

.address p {
    color: white;
    font-size: 16px;
    font-family: poppins;
    text-align: center;
    margin: 0 auto;
    font-weight: lighter;
}

.social {
    display: flex;
}

.middle {
    display: flex;
    gap: 80px;
    margin: 0 auto;
    max-width: 85%;
    margin-top: 20px;
}

.middle h1 {
    color: white;
    font-size: 20px;
    font-family: poppins;
    font-weight: bold;
}

.middle p {
    font-size: 16px;
    font-family: poppins;
    font-weight: lighter;
    color: white;
}

.SocailDiv {
    width: 30px;
    height: 30px;
    background: blue;
    border-radius: 5px;
    margin-left: 10px;
}

.SocailDiv:hover {
    box-shadow: rgba(255, 255, 255, 0.952) 0px 12px 28px, rgb(241, 238, 238) 0px 15px 12px;
    border-radius: 10px;
    transition: box-shadow 0.6s ease;
    cursor: pointer;
}

.Socialicon {
    display: flex;
    justify-content: center;
    align-items: center;
    place-items: center;
    font-size: 20px;
    color: white;
    font-weight: 400;
    margin: 15%;
}

.footerdiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.linksfooter {
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-family: poppins;
    font-weight: lighter;
}

.linksfooter:hover {
    color: black;
    cursor: pointer;
    transition: transform 5.9s ease-in-out;
}

.gMap {
    width: 200px;
    overflow: hidden;
    height: 150px;
    border-radius: 15px;
    padding-bottom: 10px;
    position: relative;
}

.gmap iframe {
    border: 0;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
}

.copyRight h1 {
    text-align: center;
    margin-top: 35px;
    color: white;
    font-size: 22px;
    font-family: poppins;
    font-weight: lighter;
}

@media only screen and (min-width: 425px) and (max-width: 900px) {
    .FooterContainer {
        max-width: 100%;
        background: #dff6e9;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        background-image: url("../assets/traningBg-3.jpg");
        background-size: cover;
        /* Ensures the image covers the container */
        background-position: center;
        height:fit-content;
        ;
        /* You can adjust this height as per your design */
        width: auto;
    }

    .Details {
        width: 100%;
        min-height: 500px;
        margin: 0 auto 30px;
        padding: 20px;
    }

    .detailsDiv {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .address {
        width: 350px;
        min-height: 206px;
        display: flex;
        align-items: center;
        border: 1px solid #8A99B4;
        padding: 10px;
        margin-bottom: 20px;
        justify-content: center;
        color: white;
    

    }
    .address:hover {
        box-shadow: #faf8f8e7 0px 12px 25px, rgba(247, 243, 243, 0.938) 0px 15px 12px;
        border-radius: 10px;
        transition: box-shadow 0.6s ease;
    }

    .middleSection {
        margin-top: 10px;
        width: 100%;
    }

    .middle {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        place-items: start;
        justify-content: flex-start;
        gap: 20px;
    }

    .gMap {
        width: 200px;
        overflow: hidden;
        position: relative;
    }

    .copyRight h1 {
        color: white;
        font-size: 22px;
        text-align: center;
        font-family: poppins;
        font-weight: lighter;
        margin-top: 35px;
    }
    .linksfooter:hover {
        color: black;
        cursor: pointer;
        transition: transform 5.9s ease-in-out;
    }
}

@media only screen and (min-width: 280px) and (max-width: 425px) {
    .FooterContainer {
        max-width: 100%;
        background: #dff6e9;
        padding: 60px 5px;
        box-sizing: border-box;
        position: relative;
        background-image: url("../assets/traningBg-3.jpg");
        background-size:cover;
        /* Ensures the image covers the container */
        background-position: center;
        height: fit-content;
        ;
        /* You can adjust this height as per your design */
        width: auto;
    }

    .Details {
        width: 100%;
        height: auto;
        margin: 0 auto 30px;
        padding: 20px 5px;
    }

    .detailsDiv {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .address {
        width: 350px;
        min-height: 206px;
        display: flex;
        align-items: center;
        border: 1px solid #8A99B4;
        padding: 10px;
        margin-bottom: 20px;
        justify-content: center;
        color: white;
    }
    .address:hover {
        box-shadow: #faf8f8e7 0px 12px 25px, rgba(247, 243, 243, 0.938) 0px 15px 12px;
        border-radius: 10px;
        transition: box-shadow 0.6s ease;
    }

    .iconDiv Link {
        font-size: 5px;
    }

    .iconDiv {
        width: auto;
        min-height: 60px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        color: white;
        gap: 2px;
    }

    .middleSection {
        margin-top: 10px;
        width: 100%;
    }

    .middle {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        place-items: start;
        justify-content: flex-start;
        gap: 20px;
    }

    .gMap {
        width: 200px;
        overflow: hidden;
        position: relative;
    }

    .copyRight h1 {
        color: white;
        font-size: 22px;
        text-align: center;
        font-family: poppins;
        font-weight: lighter;
        margin-top: 35px;

    }
    .linksfooter:hover {
        color: black;
        cursor: pointer;
        transition: transform 5.9s ease-in-out;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
    .FooterContainer {
        background: #dff6e9;
        padding: 20px;
        position: relative;
        background-image: url("../assets/traningBg-3.jpg");
        background-size: cover;
        /* Ensures the image covers the container */
        background-position: center;
        height: fit-content;
        ;
        /* You can adjust this height as per your design */
        width: auto;
    }

    .Details {
        max-width: 80%;
        margin: 0 auto 60px;
        padding: 20px;
        height: 206px;
    }

    .detailsDiv {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .address:hover {
        box-shadow: #faf8f8e7 0px 12px 25px, rgba(247, 243, 243, 0.938) 0px 15px 12px;
        border-radius: 10px;
        transition: box-shadow 0.6s ease;
    }
    .address {
        width: 350px;
    min-height: 206px;
    display: flex;
    align-items: center;
    border: 1px solid #8A99B4;
    padding: 10px;
    margin-bottom: 20px;
    justify-content: center;
    color: white;

    }

    .address .iconDiv {
        width: auto;
        min-height: 60px;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        color: white;
    }

    .iconDiv Link {
        color: blue;
    }

    .icon {
        width: 30px;
        height: 80px;
        color: white;
    }

    .addressText {
        margin-left: 10px;
        padding: 5px;
    }

    .address h1 {
        color: white;
        font-size: 20px;
        font-family: poppins;
        text-align: center;
        font-weight: lighter;
    }

    .address p {
        color: white;
        font-size: 16px;
        font-family: poppins;
        text-align: center;
        margin: 0 auto;
        font-weight: lighter;
    }

    .social {
        display: flex;
    }

    .middle {
        display: flex;
        gap: 80px;
        margin: 0 auto;
        max-width: 85%;
    }

    .middle h1 {
        color: white;
        font-size: 20px;
        font-family: poppins;
        font-weight: bold;
    }

    .middle p {
        font-size: 16px;
        font-family: poppins;
        font-weight: lighter;
        color: white;
    }

    .SocailDiv {
        width: 30px;
        height: 30px;
        background: blue;
        border-radius: 5px;
        margin-left: 10px;
    }

    .SocailDiv:hover {
        box-shadow: rgba(255, 254, 254, 0.973) 0px 12px 28px, rgba(255, 252, 252, 0.945) 0px 15px 12px;
        border-radius: 10px;
        transition: box-shadow 0.6s ease;
        cursor: pointer;
    }

    .Socialicon {
        display: flex;
        justify-content: center;
        align-items: center;
        place-items: center;
        font-size: 20px;
        color: white;
        font-weight: 400;
        margin: 15%;
    }

    .footerdiv {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .linksfooter {
        text-decoration: none;
        color: white;
        font-size: 16px;
        font-family: poppins;
        font-weight: lighter;
    }

    .linksfooter:hover {
        color: white;
        cursor: pointer;
        transition: transform 5.9s ease-in-out;
    }

    .gMap {
        width: 200px;
        overflow: hidden;
        height: 150px;
        border-radius: 15px;
        padding-bottom: 10px;
        position: relative;
    }

    .gmap iframe {
        border: 0;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        position: absolute;
    }

    .copyRight h1 {
        text-align: center;
        margin-top: 35px;
        color: white;
        font-size: 22px;
        font-family: poppins;
        font-weight: lighter;
    }
    .linksfooter:hover {
        color: black;
        cursor: pointer;
        transition: transform 5.9s ease-in-out;
    }
}