@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  /* border: 3px dashed black; */

  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
