.banner img {
    width: 100%;
}

.services {
    width: 100%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.subSerices {
    display: grid;
    grid-gap: 20px;
    /* gap: 20px; */
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 10%;
    margin-top: 10%;
    padding: 0px;
    max-width: 80%;
    margin: 0px auto;
}

.service {
    position: relative;
    padding: 40px;
    padding-bottom: 80px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.service h1 {
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 700;
    line-height: 36px;
}

.service p {
    font-size: 16px;
    font-family: 'Poppins'; 
    font-weight: 400;
    line-height: 26px;
    color: #878D97;
}

.btn{
    position: absolute;
    left: 40px;
    bottom: 40px;
    height: 35px;
    width: 140px;
    border-radius: 5px;
    padding: 7px;
    text-align: center;
    justify-content: center;
    color: #f4f6fa;
    border: navajowhite;
    background: #80669d;
    font-weight: bold;

}
.btn:hover{
    background: #ae00be;
    color: aliceblue;
    cursor: pointer;
    font-weight: bold;
}



.consult {
    display: block;
    max-width: 100%;
    min-height: 270px;
    background: rgb(105, 139, 136);
    overflow-x: auto;
    position: relative;
    /* margin: auto; */
}
.consult .img{
    position: absolute;
    display: block;
    filter: brightness(30%);
    width: 100%;
    height: 270px;
}

.consultdiv {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: aliceblue;
    padding: 100px 20px 20px;
    background: transparent;
    top:35%
}
.consultText p{
    font-size: 15px;
    font-family: poppins;
    color: rgb(255, 255, 255);
    font-weight: lighter;
    text-align: center;
}
.consultText h1{
    font-size: 25px;
    font-family: poppins;
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-align: center;
    margin:5px;
}

.btn2{
    border: none;
    background: transparent;
    color: rgb(187, 186, 189);
    text-decoration: none;
    cursor: pointer;
    font: inherit;
    padding: 5px;
    border-radius: 5px;
    font-size: 25px;
    font-family: poppins;
    font-weight: lighter;
    margin-left: 10px;
    margin-top: 5px;
}

.btn2:hover{
    background: rgb(139, 138, 138);
    color: white;
}

.specializationContainer{
    align-items: center;
    background: #f7fafc;
    margin: 0 auto;
    max-width: 100%;
}

.specializationDiv{
    align-items: center;
    margin: 0px auto;
    padding: 20px;
    max-width: 80%;
}
.specializationDiv h3{
    font-size: 18px;
    align-items: center;
    font-family: poppins;
    color: rgb(109, 5, 245);
    font-weight: lighter;
    text-align: center;
    margin-top: 10px;

}

.specializationDiv h2{
    font-size: 40px;
    align-items: center;
    font-family: poppins;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;

}

.specialization{
    display: flex;
    justify-content:space-around ;
    object-fit: cover;
}
.specialization img{
    width: 100px;
}
.arrow {
    margin-top: 30px;
    margin-left: -50%;
}
.specialization h1{
    font-size: 22px;
    font-family: poppins;
    color: rgb(24, 19, 19);
    font-weight: bold;
    text-align:left;
    margin-top: 10px;
}
.specialization p{
    font-size: 15px;
    font-family: poppins;
    color: rgb(138, 137, 137);
    font-weight: lighter;
    text-align:left;
    margin-top: 10px;
}
@media (max-width:768px) and (min-width:375px){
    .consult .img{
        position: absolute;
        display: block;
        filter: brightness(30%);
        width: 100%;
        height: 100%;
    }
    .subSerices {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
       place-items: center;
    }
    .specialization{
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
       place-items: center;
    }
    .consultdiv {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
       place-items: center;
       gap: 40px;
    }
    .arrow{
        display: none;
    }
    
}
@media screen and (max-width: 900px) {

    .consult .img{
        position: absolute;
        display: block;
        filter: brightness(30%);
        width: 100%;
        height: 100%;
    }
    .subSerices {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
       place-items: center;
    }
    .specialization{
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
       place-items: center;
    }
    .consultdiv {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
       place-items: center;
       gap: 40px;
    }
    .arrow{
        display: none;
    }
}